import React, { useEffect, useState } from "react";
import { useSnackbar } from "@mui/base";
import { Grid } from "@mui/material";
import CustomSnackbar from "../Components/CustomSnackbar";
import { useLocation } from "react-router-dom";

function Homepage() {
  const [open, setOpen] = React.useState(false);
  const { pathname, search } = useLocation();
  const [userAgent, setUserAgent] = useState("");

  useEffect(() => {
    // Set the user agent in the component state on mount
    setUserAgent(navigator.userAgent || window.opera);

    // You can also use window.navigator.userAgent if you prefer
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const { getRootProps, onClickAway } = useSnackbar({
    onClose: handleClose,
    open,
    autoHideDuration: 2000,
  });

  const handleOpen = (message) => {
    setOpen(message);
  };

  function getMobileOperatingSystem() {
    const response = {
      imageUrl: "",
      redirectUrl: "",
    };

    if (/android/i.test(userAgent)) {
      response.platform = "Android";
      response.imageUrl = require("../Assets/Images/google-play.png");
      response.redirectUrl =
        "https://play.google.com/store/apps/details?id=com.senkron.energy.enpact";

      return response;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (
      /iPad Simulator|iPhone Simulator|iPod Simulator|iPad|iPhone|iPod|Safari|Apple Computer, Inc./.test(
        userAgent
      ) &&
      !window.MSStream
    ) {
      response.platform = "IOS";
      response.imageUrl = require("../Assets/Images/app-store.png");
      response.redirectUrl =
        "https://apps.apple.com/us/app/enpact/id6448427584";
    }

    return response;
  }

  useEffect(() => {
    window.location.href = pathname
      ? `enpact:/${pathname + search}`
      : `enpact://`;
  }, [pathname, search]);

  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        height: "80vh",
        flexDirection: "column",
      }}
    >
      <img
        src={require("../Assets/Images/logo.png")}
        alt="Enpact Logo"
        width="30%"
        style={{
          maxWidth: 300,
        }}
      />
      <img
        src={require("../Assets/Images/enpact-font.png")}
        alt="Enpact text logo"
        width="30%"
        style={{
          maxWidth: 300,
        }}
      />

      {getMobileOperatingSystem()?.imageUrl && (
        <button
          onClick={() => {
            handleOpen("Redirected to Download from Store");
            setTimeout(() => {
              window.location.replace(
                getMobileOperatingSystem()?.redirectUrl || ""
              );
            }, 500);
          }}
          style={{
            cursor: "pointer",
            position: "absolute",
            bottom: 0,
            maxWidth: "80%",
            backgroundColor: "transparent",
          }}
        >
          <img
            src={getMobileOperatingSystem().imageUrl}
            alt="Redirected to Download from Store"
            width={"100%"}
          />
        </button>
      )}

      {open ? (
        <CustomSnackbar
          onClickAway={onClickAway}
          getRootProps={getRootProps}
          open={open}
        />
      ) : null}
    </Grid>
  );
}

export default Homepage;
